import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { navigate } from "gatsby";

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"

import useScript from "../service/useScript"

import heartCare from "../../static/assets/images/icons/heart_care_white.svg"
import drDummy from "../../static/assets/images/dr_dummy.jpg"

const KneeImplantPricing = ({ data: { allNodeTemplatePages: { edges } } }) => {

  let [activeTab, setActiveTab] = useState(1)

  const [pageData] = edges;

  const metaTags = Array.isArray(pageData?.node?.metatag_normalized) && pageData?.node?.metatag_normalized.length > 0 ? pageData?.node?.metatag_normalized : [];
  const schema = pageData?.node?.relationships?.field_seo_schema ? pageData?.node?.relationships?.field_seo_schema : []
  const canonicalUrl = pageData?.node?.metatags_on_page || {}
  const title = typeof (pageData?.node?.title) !== "undefined" && pageData?.node?.title.length > 0 ? pageData?.node?.title : "KneeImplantPricing"

  const pageDetails = typeof (pageData) !== "undefined" && Array.isArray(pageData?.node?.relationships?.field_component_type) && pageData?.node?.relationships?.field_component_type.length > 0 && pageData?.node?.relationships?.field_component_type


  // basic icons and titles section
  const sectionData = pageDetails.find(el => el.name === "orthopaedics_care_icon_section");

  const sec1 = typeof (sectionData?.relationships?.components) !== "undefined" && sectionData?.relationships?.components.length > 0 && sectionData?.relationships?.components[0];
  const description = typeof (sec1?.description?.processed) !== "undefined" && sec1?.description?.processed.length > 0 ? sec1?.description?.processed : ""
  const icon = sec1?.relationships?.image?.uri?.url;
  const iconAlt = sec1?.image?.alt;
  const secTitle = typeof (sec1?.title) !== "undefined" && sec1?.title.length > 0 ? sec1?.title : "";

  // tabs
  const tabsSection = pageDetails.find(el => el.name === "Tabular Format");
  const tabsSectionData = typeof (tabsSection?.relationships?.components) !== "undefined" && Array.isArray(tabsSection?.relationships?.components) && tabsSection?.relationships?.components

  useScript("/assets/js/care-home.js")


  return (
    <>
      <Layout>
        <Meta
          files={{
            js: [],
            css: [
              "/assets/css/about-through-the-ages.css",
              "/assets/css/services-detail.css",
              "/assets/css/accessibility.css",
              "/assets/css/orthopaedics.css",
            ],
          }}
          tags={{metaTags,canonicalUrl}}
        />
        <Schema schema={schema} />
        <main className="innerpage pt-first-section">
          <section class="speciality_section mt-4">
            <div class="container">
              <div class="section-head">
                <h2>{title}</h2>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  {/* <div class="aboutContainer diseaseContainer">
                    <ul class="nav nav-tabs gallery-tabs" role="tablist">

                      {
                        Array.isArray(tabsSectionData) && tabsSectionData.map((el, index) => {
                          return (
                            <li role="presentation" className="nav-item" key={index} >
                              <a className={`nav-link ${activeTab === index + 1 ? 'active' : ""}`} data-toggle="tab" href={`#${el?.field_title}`} role="tab" aria-controls={el?.field_title} aria-selected="true" onClick={() => setActiveTab(index + 1)} >{el?.field_title}</a>
                            </li>
                          )
                        })
                      }

                    </ul>
                  </div> */}
                  <div class="about-ages">
                    <div class="tab-content mt-3">
                      {
                        Array.isArray(tabsSectionData) && tabsSectionData.map((el, index) => {
                          return (
                            <div className={`tab-pane ${activeTab === index + 1 ? 'active' : ""} `} id={el?.field_title} role="tabpanel" aria-labelledby={el?.field_title} key={index}>
                              <div className="card">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div dangerouslySetInnerHTML={{ __html: el?.text?.processed }}>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
query kneeImplantPricing {

  allNodeTemplatePages(filter: {path: {alias: {regex: "/knee-implant-pricing$/"}}}) {

    edges {

      node {

        id

        title

        path {

          alias

        }
        relationships{
          field_seo_schema{
              field_seo_title
              field_seo_schema_text_area
          }
        }
        metatag_normalized {

          attributes {

            content

            name
            property

          }

        }
        metatags_on_page: field_meta_tags{
          canonical_url
        }
        relationships {

          field_component_type {

            __typename

            ...ParagraphSection2

          }

        }

      }

    }

  }

}



fragment ParagraphSection2 on paragraph__section {

  id

  name: field_name

  relationships {

    components: field_components {

      __typename

      ...ParagraphTitleAndDescription2

    }

  }

}



fragment ParagraphTitleAndDescription2 on paragraph__title_and_description {

  id

  field_title

  text: field_description {

    processed

  }

  field_cta_link {

    title

    uri

  }

  field_text {

    processed

  }

}
`

export default KneeImplantPricing